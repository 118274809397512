document.addEventListener("DOMContentLoaded", function(event) {

	// var b = document.documentElement;
	//     b.setAttribute('data-useragent',  navigator.userAgent);
	//     b.setAttribute('data-platform', navigator.platform );
	//     b.className += ((!!('ontouchstart' in window) || !!('onmsgesturechange' in window))?' touch':'');
	
	Number.prototype.clamp = function(min, max) {
		return Math.min(Math.max(this, min), max);
	};

	// ---------------------------------------- PHP Import Variables

	var templateUrl = phpimport.templateUrl;

	function attrSet(el, attrs) {
		for (var key in attrs) {
			el.setAttribute(key, attrs[key]);
		}
	}

	var attrToggle = (elements, attributes) => {
		if (elements.getAttribute(attributes[0]) === "true") {
			elements.setAttribute(attributes[0], "false");
			elements.setAttribute(attributes[1], "true");
		} else {
			elements.setAttribute(attributes[0], "true");
			elements.setAttribute(attributes[1], "false");
		}
	};

	// ---------------------------------------- Initiate CSS Modules

	// if ("paintWorklet" in CSS) {
	//     (CSS.paintWorklet || paintWorklet).addModule(templateUrl + "/js/modules/squircle.js");
	// }

	// if ('paintWorklet' in CSS) {
	//     // Safari TP fix.
	//     // Safari only accepts source code as a string instead file path.
	//     if (navigator.userAgent.includes('Safari')
	//     && !navigator.userAgent.includes('Chrome')) {
	//         (async function() {
	//             const response = await fetch(templateUrl + '/js/modules/squircle.js');
	//             const blob = await response.blob();
	//             const reader = new FileReader();
	//             reader.addEventListener('load', () => {
	//                 CSS.paintWorklet.addModule(reader.result);
	//             });
	//             reader.readAsText(blob);
	//         })();
	//     } else {
	//         CSS.paintWorklet.addModule(templateUrl + '/js/modules/squircle.js');
	//     }
	// }

	// ---------------------------------------- Allow active elements to work on mobile.

	document.body.addEventListener("touchstart", event => {
		passive: true;
	});

	// var dateAdjust = document.querySelectorAll('.ginput_container_date input.datepicker');
	// dateAdjust.forEach(el => {
	//     el.setAttribute('type','date');
	// });

	var dateAdjustFunc = () => {
		var dateAdjust = document.querySelectorAll('.ginput_container_date input.datepicker');
		var i = 0, length = dateAdjust.length;
		for (; i < length; i++) {
			dateAdjust[i].setAttribute('type', 'date');
		}
	};
	dateAdjustFunc();


	// const test = document.querySelectorAll('.ginput_container_radio .gfield_radio li>label');
	// var i = 0, length = test.length;
	//     for ( ; i < length; i++) {
	//         test[i].addEventListener("pointerdown", function(ev) {
	//             console.log("BOOM TOWN");
	//             this.style.Transform = 'scale(0.5)';
	//             this.style.backgroundColor = 'red';
	//         });
	//     }


	// ---------------------------------------- Initiate Service Workers

	// if ('serviceWorker' in navigator) {
	// window.addEventListener('load', function() {
	//     navigator.serviceWorker.register('wp-content/themes/Ignition/js/cores/font-core.js').then(function(registration) {
	//         // Registration was successful
	//         console.log('ServiceWorker registration successful with scope: ', registration.scope);
	//     }, function(err) {
	//         // registration failed :(
	//         console.log('ServiceWorker registration failed: ', err);
	//     });
	// });
	// }

	// ---------------------------------------- Gravity Forms Code

	// document.addEventListener("gform_confirmation_loaded", () => {
	//     console.log('YES VANILLA');
	//     bodyScrollLock.clearAllBodyScrollLocks();
	//     document.body.classList.remove('con-clicked');
	//     document.body.classList.add('form-sent');
	// });
	let formTitles = document.querySelectorAll('.gform_title');
	formTitles.forEach(title => {title.classList.add('title')});

	$(document).on('gform_confirmation_loaded', function() {
		bodyScrollLock.clearAllBodyScrollLocks();
		document.body.classList.remove('con-clicked');
		document.body.classList.add('form-sent');
	});

	// $(document).on("gform_post_render", function() {
	//     // Code to trigger on AJAX form render.
	// });

	// ---------------------------------------- SECTION 5: Initiate Scroll-Lock Plugin

	const scrollMenu = document.querySelector(".menu");
	const scrollSlideout = document.querySelector(".header-form");

	// ---------------------------------------- SECTION 6: Ionicon Injector

	let heroList = document.querySelector('.page .content-hero ul');
	if (heroList) {
		heroList.dataset.loaded = 'true';
		for (let el of heroList.children) {
			let icon = document.createElement('ion-icon');
			icon.setAttribute('src', '../../wp-content/themes/Modular1/images/icons/chevron-left.svg');
			el.prepend(icon);
		}
	}

	let singleList = document.querySelector('.single .content-hero ul');
	if (singleList) {
		singleList.dataset.loaded = 'true';
		for (let el of singleList.children) {
			let icon = document.createElement('ion-icon');
			icon.setAttribute('src', '../wp-content/themes/Modular1/images/icons/chevron-left.svg');
			el.prepend(icon);
		}
	}

	let sidebarList = document.querySelector('.widget_recent_entries ul');
	if (sidebarList) {
		sidebarList.dataset.loaded = 'true';
		for (let el of sidebarList.children) {
			let icon = document.createElement('ion-icon');
			icon.setAttribute('src', '../../wp-content/themes/Modular1/images/icons/chevron-left-red.svg');
			el.prepend(icon);
		}
	}

	var iconInject = (el, icn, parent) => {
		var i = 0,
			length = el.length;
		for (; i < length; i++) {
			let iconTemplate = document.createElement("ion-icon");
			if (icn.startsWith() !== '/') {
				iconTemplate.setAttribute('name', icn);
				if (parent === 'true') {
					el[i].parentNode.prepend(iconTemplate);
				} else {
					el[i].prepend(iconTemplate);
				}
			}
			else {
				iconTemplate.setAttribute('src', icn);
				if (parent === 'true') {
					el[i].parentNode.prepend(iconTemplate);
				} else {
					el[i].prepend(iconTemplate);
				}
			}
		}
	};

	let iconSelectEl = document.querySelectorAll('select');
	iconInject(iconSelectEl, 'ios-arrow-down', 'true');
	// let iconSelectVar = document.querySelectorAll('var');
	//     iconInject(iconSelectVar,'ios-code','true');
	// let iconSelectDel = document.querySelectorAll('del');
	//     iconInject(iconSelectDel,'ios-close-circle-outline','true');
	// let iconSelectIns = document.querySelectorAll('ins');
	//     iconInject(iconSelectIns,'ios-return-right','true');   
	let iconSelectAcc = document.querySelectorAll('main .accordion-icon');
	iconInject(iconSelectAcc, 'ios-arrow-down', 'false');

	// ---------------------------------------- SECTION 7: Force Layout Recalculation
	// Workaround for Webkit bug: force scroll height to be recomputed after the transition ends, not only when it starts.

	// function resetPlacement(element) {
	//     element.on("webkitTransitionEnd", function() {
	//         $(element).hide().offset();
	//         $(element).show();
	//     });
	// }

	function resetPlacementEs6(el) {
		// console.log(el.length);
		var i = 0,
			length = el.length;
		for (; i < length; i++) {
			let obj = el[i];
			obj.addEventListener("webkitTransitionEnd", event => {
				obj.style.display = 'none';
				obj.getBoundingClientRect();
				obj.style.display = '';
			});
		}
	}

	// ---------------------------------------- SECTION 8: Fancybox Functions

	$("[data-fancybox]").fancybox({
		buttons: ["slideShow", "share", "zoom", "fullScreen", "close"],
		image: {
			preload: false
		},
		animationEffect: "zoom",
		transitionEffect: "slide"
	});

	$(".fancybox").fancybox({
		buttons: ["slideShow", "share", "zoom", "fullScreen", "close"],
		image: {
			preload: false
		},
		animationEffect: "zoom",
		transitionEffect: "slide"
	});

	// Enable fancybox support on default Wordpress galleries.
	$(".gallery-item a").fancybox({
		buttons: ["slideShow", "share", "zoom", "fullScreen", "close"],
		image: {
			preload: false
		},
		animationEffect: "zoom",
		transitionEffect: "slide",
		beforeShow: function() {
			bodyScrollLock.disableBodyScroll(scrollMenu);
		},
		afterClose: function() {
			bodyScrollLock.enableBodyScroll(scrollMenu);
		}
	});

	$(".gallery").each(function() {
		// set the rel for each gallery
		$(this).find(".gallery-icon a").attr("data-fancybox", $(this).attr("id"));
	});

	// ---------------------------------------- SECTION 9: Slideout Form Animation

	// var slideIn = anime({
	//     targets: ".header-form",
	//     translateX: ["110%", "0"],
	//     duration: 800,
	//     elasticity: 200,
	//     autoplay: false,
	//     direction: "alternate",
	//     easing: "easeInOutElastic",
	//     delay: function(el, i, l) {
	//         return i * 200;
	//     },

	//     complete: function(anim) {
	//         // anime.remove('.slideout-form');
	//         $(".header-form").css("transform-origin", "bottom right");
	//         anime({
	//             targets: ".header-form",
	//             translateY: "24px",
	//             translateX: "-24px",
	//             scale: [1, 0],
	//             rotate: -20,
	//             opacity: 0.25,
	//             duration: 1000,
	//             easing: "easeInBack",
	//             delay: function(el, i, l) {
	//                 return i * 200;
	//             },
	//             complete: function(anim) {
	//                 anime({
	//                     targets: ".header-form__button",
	//                     scale: [
	//                         { value: 1.05, duration: 100 },
	//                         { value: 1, duration: 100 }
	//                     ],
	//                     duration: 200,
	//                     easing: "easeOutSine"
	//                 });
	//             }
	//         });

	//         $(document).on("gform_confirmation_loaded", function() {
	//             anime({
	//                 targets: ".header-form",
	//                 translateY: 200,
	//                 translateX: "-100%",
	//                 scale: [1, 0.25],
	//                 rotate: 20,
	//                 opacity: 0.5,
	//                 duration: 3000,
	//                 easing: "easeInOutElastic",
	//                 delay: function(el, i, l) {
	//                     return i * 200;
	//                 }
	//             });
	//         });
	//     }

	// });

	// ---------------------------------------- Header Toggling Code

	var headerScripting = () => {

		const ariaHide = ["aria-hidden", "aria-expanded"];
		const ariaChecked = ["aria-checked"];
		const header = document.getElementsByTagName("header")[0];

		// -----

		let overlay = header.querySelector(".header-overlay");
		let toggle = header.querySelector(".header__toggle");
		let mobilenav = header.querySelector(".header-sidebarnav");
		let form = document.querySelector(".header-form");
		let button = document.querySelector(".header-form__button");
		let landingButton = document.querySelector(".cta-button");

		// let mobilehead = header.querySelector(".header-mobile");
		// let desktophead = header.querySelector(".header-desktop");


		// -----

		const headerArray = [mobilenav, overlay, toggle, header];
		resetPlacementEs6(headerArray);

		// -----

		overlay.addEventListener("click", event => {
			document.body.dataset.formToggle = false;
			attrSet(mobilenav, { "aria-expanded": "false", "aria-hidden": "true" });
			attrSet(form, { "aria-expanded": "false", "aria-hidden": "true" });
			toggle.setAttribute("aria-checked", "false");
			overlay.dataset.active = "false";
			bodyScrollLock.clearAllBodyScrollLocks();
		});

		toggle.addEventListener("click", event => {
			attrToggle(toggle, ariaChecked);
			bodyScrollLock.enableBodyScroll(scrollMenu);
			if (form.getAttribute("aria-expanded") === "true" &&
				overlay.dataset.active === "true"
			) {
				attrToggle(mobilenav, ariaHide);
			} else if (
				form.getAttribute("aria-expanded") === "false" &&
				overlay.dataset.active === "true"
			) {
				attrToggle(mobilenav, ariaHide);
				overlay.dataset.active = "false";
			} else {
				attrToggle(mobilenav, ariaHide);
				overlay.dataset.active = "true";
				bodyScrollLock.disableBodyScroll(scrollMenu);
			}
		});

		button.addEventListener("click", event => {
			bodyScrollLock.enableBodyScroll(scrollSlideout);
			document.body.dataset.formToggle = true;
			if (
				mobilenav.getAttribute("aria-expanded") === "true" &&
				overlay.dataset.active === "true"
			) {
				attrToggle(form, ariaHide);
			} else if (
				mobilenav.getAttribute("aria-expanded") === "false" &&
				overlay.dataset.active === "true"
			) {
				attrToggle(form, ariaHide);
				overlay.dataset.active = "false";
			} else {
				attrToggle(form, ariaHide);
				overlay.dataset.active = "true";
				bodyScrollLock.disableBodyScroll(scrollSlideout);
			}
		});

		if (landingButton !== null) {
			landingButton.addEventListener("click", event => {
				event.preventDefault();
				bodyScrollLock.enableBodyScroll(scrollSlideout);
	
				if (
					mobilenav.getAttribute("aria-expanded") === "true" &&
					overlay.dataset.active === "true"
				) {
					attrToggle(form, ariaHide);
				} else if (
					mobilenav.getAttribute("aria-expanded") === "false" &&
					overlay.dataset.active === "true"
				) {
					attrToggle(form, ariaHide);
					overlay.dataset.active = "false";
				} else {
					attrToggle(form, ariaHide);
					overlay.dataset.active = "true";
					bodyScrollLock.disableBodyScroll(scrollSlideout);
				}
			});
		}
		
	};

	headerScripting();

	// var StickyHeader = (function(window, document) {

	// 	const topTolerance = 8;
	// 	const scrolledTolerance = 24;
	// 	let hasScrolled = false;
	// 	let lastScrollTop = 0;

	// 	var scrollRedraw = function() {
	// 		const pageY = window.scrollY;

	// 		if (pageY > (lastScrollTop + scrolledTolerance)) {
	// 			header.dataset.scrolled = true;
	// 			buttonObj.dataset.scrolled = true;
	// 		}
	// 		if (pageY < lastScrollTop || pageY <= 0) {
	// 			header.dataset.scrolled = false;
	// 			buttonObj.dataset.scrolled = false;
	// 		}
	// 		if (pageY <= (0 + topTolerance)) {
	// 			header.dataset.top = true;
	// 			buttonObj.dataset.top = true;
	// 		}
	// 		if (pageY >= (0 + topTolerance)) {
	// 			header.dataset.top = false;
	// 			buttonObj.dataset.top = false;
	// 		}
	// 		lastScrollTop = pageY;
	// 		hasScrolled = false;
	// 	};
	// 	var scrollWatch = function() {
	// 		if (!hasScrolled) {
	// 			window.requestAnimationFrame(scrollRedraw);
	// 		}
	// 		hasScrolled = true;
	// 		window.requestAnimationFrame(scrollWatch);
	// 	};

	// 	scrollWatch();
	// }(window, document));

	// ---------------------------------------- Nav Collapse

	var accMenuParent = $('.header-sidebarnav .menu > .menu-item-has-children');
	var accMenuContent = $('.header-sidebarnav .menu > .menu-item-has-children > .sub-menu');
	accMenuParent.addClass('accordion-box');
	accMenuContent.addClass('accordion-content');

	function accHideDefault(selector) {
		var element = document.querySelectorAll(selector);
		var i = 0,
			length = element.length;
		for (; i < length; i++) {
			element[i].style.maxHeight = 0;
			element[i].dataset.collapsed = "true";
		}
	}
	accHideDefault('.accordion-content');

	// -----

	function collapseSection(element) {
		var sectionHeight = element.scrollHeight + "px";
		var elementTransition = element.style.transition;
		element.style.transition = "";
		requestAnimationFrame(function() {
			element.style.maxHeight = sectionHeight,
			element.style.transition = elementTransition;
			requestAnimationFrame(function() {
				element.style.maxHeight = 0;
			});
		});
		element.dataset.collapsed = "true";
	}

	function expandSection(element) {
		var heightBasis = element.scrollHeight + "px";
		element.style.maxHeight = heightBasis;

		function expand() {
			element.removeEventListener("transitionend", expand);
			$(element).hide().offset();
			$(element).show();
		}
		element.addEventListener("transitionend", expand);
		element.dataset.collapsed = "false";
	}

	// -----

	var collapseHandler = (el, accContent, accToggle) => {
		let accCollapsed = accContent.dataset.collapsed === "true";
		if (accCollapsed) {
			expandSection(accContent);
			el.dataset.toggled = "true";

		} else {
			collapseSection(accContent);
			el.dataset.toggled = "false";
		}
	};
	var collapseBase = () => {
		const accList = document.querySelectorAll('.accordion-box');
		var i = 0,
			length = accList.length;
		for (; i < length; i++) {
			let el = accList[i];
			let accToggle = el.querySelector('.accordion-toggle');
			let accContent = el.querySelector('.accordion-content');
			accToggle.addEventListener('click', (event) => {
				event.preventDefault();
				collapseHandler(el, accContent, accToggle);
			});
		}
	};
	collapseBase();

	// ---------------------------------------- Set "noopener" to all anchor tags.

	var linkNoopener = () => {
		const links = document.querySelectorAll('a:not([rel=ignore])');
		var i = 0,
			length = links.length;
		for (; i < length; i++) {
			links[i].rel += ' noopener';
		}
	};
	linkNoopener();

	var linkBoth = () => {
		const links = document.querySelectorAll('.home .credits a:not([rel=ignore])');
		var i = 0,
			length = links.length;
		for (; i < length; i++) {
			links[i].rel += ' nofollow';
		}
	};
	linkBoth();

	// $("a[rel!='ignore']").each(function() {
	//     this.rel += " noopener";
	// });
	// $(".home .credits a[rel!='ignore']").each(function() {
	//     this.rel += " nofollow";
	// });

	// ---------------------------------------- Resizing Objects


	const resizeCore = (resizeFunct, resizeInt) => {
		var resizeTimer;
		["resize", "load"].forEach(event => window.addEventListener(event, () => {
			clearTimeout(resizeTimer);
			resizeTimer = setTimeout(resizeFunct, resizeInt);
		}));
	}

	const slideResize = (parentArray, childArrayClass) => {
		var sizeList = [];
		parentArray.forEach(container => {
			let resizeObjects = container.querySelectorAll(childArrayClass);
			resizeObjects.forEach(child => {
				sizeList.push(child.offsetHeight);
			})
			var maxHeight = Math.max.apply(null, sizeList);
			container.style.height = maxHeight + "px";
		})
	}
	
	// ----- Load the variables beforehand.
	const resizeContainer = document.querySelectorAll(".object-slidewrap--resize");
	const resizeChildClass = '.object-slide--resize';
	resizeCore(() => slideResize(resizeContainer, resizeChildClass), 500);


	// ---------------------------------------- Intersection observer lazy-load.

	// Get all of the images that are marked up to lazy load
	const images = document.querySelectorAll(
		".contentbox-image, .object-image"
	);
	// For IE 11 compatibility.
	const imagesArray = Array.from(images);

	const config = {
		// If the image gets within 50px in the Y axis, start the download.
		rootMargin: "50px 0px",
		threshold: 0.01
	};

	let imageCount = images.length;
	let observer = new IntersectionObserver(onIntersection, config);
	imagesArray.forEach(image => {
		observer.observe(image);
	});

	function onIntersection(entries) {
		if (imageCount === 0) {
			observer.disconnect();
		}
		// Loop through the entries
		entries.forEach(entry => {
			// Are we in viewport?
			if (entry.intersectionRatio > 0) {
				// Stop watching and load the image
				observer.unobserve(entry.target);
				preloadImage(entry.target);
			}
		});
	}

	function fetchImage(url) {
		return new Promise((resolve, reject) => {
			const image = new Image();
			image.src = url;
			image.onload = resolve;
			image.onerror = reject;
		});
	}

	function preloadImage(image) {
		// Not to be confused with actual src. this is the dataset.
		const src = image.dataset.src;
		if (!src) {
			return;
		}

		return fetchImage(src).then(() => {
			applyImage(image, src);
		});
	}

	function applyImage(img, src) {
		// Prevent this from being lazy loaded a second time.
		img.parentElement.classList.add("img-loaded");
		img.classList.add("img-loaded");
		img.src = src;
		img.classList.add("fade-in");
	}

	// ---------------------------------------- Select updating tweaks.

	$("select").on("change", function() {
		var className = $(this).find("option:selected").attr("class");
		if (className !== "gf_placeholder") {
			$(this).addClass("placeholder-hidden");
		} else {
			$(this).removeClass("placeholder-hidden");
		}
	});

	// ---------------------------------------- Add checkmark SVG to compatible elements.

	// var toggleParent = document.querySelectorAll('.formField_toggle .gfield_checkbox li');
	// var toggleInner = document.createElement('div');

	// toggleInner.classList.add('toggle_inner');
	// toggleParent.forEach(el => {
	// 	let toggleClone = toggleInner.cloneNode(true);
	// 	el.appendChild(toggleClone);
	// });

	// -----

	// var checkbox = document.querySelectorAll('input[type=checkbox]');

	// var svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
	// svg.setAttributeNS("http://www.w3.org/2000/xmlns/", 'xmlns', 'http://www.w3.org/2000/svg');
	// attrSet(svg, { "class": "checkmark", "viewBox": "0 0 16 16" });
	// var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
	// attrSet(path, { "class": "checkmark--check", "fill": "none", "d": "M 4.5 8.5 l 2 2 5-5" });

	// svg.appendChild(path);
	// checkbox.forEach(el => {
	// 	let checkboxParent = el.parentElement;
	// 	let svgClone = svg.cloneNode(true);
	// 	checkboxParent.appendChild(svgClone);
	// });

	// ---------------------------------------- Experimental - sidebar shadow responds to screen width.
	
	const resizeSentinel = document.querySelector('body');

	if ('ResizeObserver' in window ) {
		const sidebar = document.querySelector('.sidebar-sticky');
		const resizeWatch = new ResizeObserver( entries => {
		
			if (sidebar !== null) {
				// -----
				for (let entry of entries) {
					const objHeight = entry.contentRect.height;
					const shadowVal = (breakpoint, spread, clampPre, clampPost) => {
						return ((objHeight - breakpoint) / spread).clamp(clampPre,clampPost);
				};
				sidebar.style.boxShadow = 
					`0 0 0 1px rgba(0,0,0,${shadowVal(768,-1000,0,0.05)}),
					0 ${shadowVal(768,-50,0,2)}px ${shadowVal(768,-25,0,4)}px 0 rgba(0,0,0,${shadowVal(768,-1000,0,0.1)}),
					0 ${shadowVal(768,-50,0,8)}px ${shadowVal(768,-16.5,0,24)}px 0 rgba(0,0,0,${shadowVal(768,-1000,0,0.1)})`;
				}
			}
		});
		resizeWatch.observe(resizeSentinel);
	}

	// ---------------------------------------- Experimental - Resize Hero on mobile to fit viewport innerHeight

	// If mobile - need to check this.
	
	if ('ResizeObserver' in window ) { 

		console.warn('Browser supports resizeObserver.')
		
		const resizeHeroInit = new ResizeObserver( entries => {
			// -----
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
			// -----
		});
		resizeHeroInit.observe(resizeSentinel);
	}
	else { 
		console.warn('Browser does not support resizeObserver.')
		
		// const resizeHeroInit = (obj) => {
		// 	let vh = window.innerHeight * 0.01;
		// 	obj.style.maxHeight = (vh * 100) + 'px';
		// }
		// // const resizeHeroChildInit = (obj) => {
		// // 	let wO = window.outerHeight;
		// // 	let wI = window.innerHeight;

		// // 	let sizeW = wO - wI + 'px';
		// // 	console.log(sizeW);
		// // 	obj.style.transform = 'translateY('+sizeW+')';
		// // }

		// const resizeHero = document.querySelector(".section-hero .object-slidewrap");
		// resizeHero.style.minHeight = 0;
		// resizeHero.style.height = '100vh';
		// resizeHero.style.transition = '0.30s max-height ease';
		// // -----
		// // const resizeChildren = resizeHero.querySelectorAll(".slide-content");
		// // resizeChildren.forEach(child => {
		// // 	resizeCore(() => resizeHeroChildInit(child), 500)
		// // })
		// resizeCore(() => resizeHeroInit(resizeHero), 500)
	}

// ---------------------------------------- Checking for placeholders and values.

	// const inputEl = document.querySelectorAll('input, textarea');
	// const selectEl = document.querySelectorAll('select');

	// // ----- Function for input elements.

	// const setPlaceholderInput = (input) => {
	// 	input.setAttribute("value", input.value);
	// 	input.getAttribute('placeholder') && input.value === ''
	// 		? input.dataset.placeholderVisible = true
	// 		: input.dataset.placeholderVisible = false
	// 	;
	// }

	// // ----- Function for select elements.

	// const setPlaceholderSelect = (select) => {
	// 	let options = select.querySelectorAll('option');
	// 	let optionSelected = select.querySelector('option:checked');
	// 	let placeholderStr = 'gf_placeholder';

	// 	select.dataset.placeholderVisible = false;
	// 	optionSelected.classList.contains(placeholderStr)
	// 		? select.dataset.placeholderVisible = true
	// 		: select.dataset.placeholderVisible = false
	// 	;
	// 	for (i = 0; i < options.length; i++) {
	// 		let option = options[i];
	// 		option.selected == true 
	// 			? option.setAttribute('selected', 'selected')
	// 			: option.removeAttribute('selected')
	// 		;
	// 	}
	// }

	// // ----- Load functions.

	// for (i = 0; i < inputEl.length; i++) {
	// 	let el = inputEl[i];
	// 	setPlaceholderInput(el);
	// 	// -----
	// 	el.addEventListener('keyup', event => {
	// 		setPlaceholderInput(el);
	// 	});
	// };

	// for (i = 0; i < selectEl.length; i++) {
	// 	let el = selectEl[i];
	// 	setPlaceholderSelect(el);
	// 	// -----
	// 	el.addEventListener('change', event => {
	// 		setPlaceholderSelect(el);
	// 	});
	// }

	// ---------------------------------------- Gravity Forms range slider.

	function sliderChange() {
		const rangeSliders = document.querySelectorAll('.ginput_container_range');
		rangeSliders.forEach(slider => slider.addEventListener('input', event => {
			let label = slider.previousSibling;
			let inputVal = slider.children[0].value;
			label.innerHTML = 'Value: ' + inputVal;
		}));
	}
	sliderChange();

	// ---------------------------------------- Check labels sizing in Gravity Forms.

	var checkLabelSize = (el) => {
		var i = 0,
			length = el.length;
		for (; i < length; i++) {

			let label = el[i].nextElementSibling;
			let labelW = label.offsetWidth;
			let input = el[i];
			let inputW = input.offsetWidth;
			let valW = input.placeholder.length * 8 + 24;
			let visibleW = inputW - labelW;

			if (valW > visibleW) {
				label.dataset.overlapped = "true";
			} else {
				label.dataset.overlapped = "false";
			}
		}
	};

	// ---------------------------------------- Slider Scripting

	// Checking for Page Visibility API and setting relevant names.
	var hidden, visibilityChange;
	if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support 
		hidden = "hidden";
		visibilityChange = "visibilitychange";
	} else if (typeof document.msHidden !== "undefined") {
		hidden = "msHidden";
		visibilityChange = "msvisibilitychange";
	} else if (typeof document.webkitHidden !== "undefined") {
		hidden = "webkitHidden";
		visibilityChange = "webkitvisibilitychange";
	}

	// -----

	const sliderInit = (sliderName, animTime, animDelay, animEase) => {

		// console.log(document.querySelector('.slide-wrap'));
		// Test arguments
		animTime ? animTime : animTime = 2000;
		animDelay ? animDelay : animDelay = 500;
		animEase ? animEase : animEase = 'ease';

		// Set variables
		let animIndex = 1;
		let i = 0;

		// Coding
		const sliderParent = document.querySelector(sliderName);

		const sliderChild = sliderParent.children;
		let length = sliderChild.length;

		if (length => 0 && sliderParent !== undefined) {
			// Enable dots as alternate navigation.
			// const dotContainer = document.createElement('ul');
			// dotContainer.classList.add('slider-dots');
			// sliderParent.appendChild(dotContainer);

			// Pre-flight
			for (i = 0; i < length; i++) {
				let el = sliderChild;
				el[i].dataset.sliderObject = true;
				el[i].dataset.sliderActive = false;
				el[0].dataset.sliderActive = true;
				// let dot = document.createElement('li');
				// let button = document.createElement('span');
				// attrSet(button, {
				// 	"role": "button",
				// 	"aria-controls": "slide-" + i,
				// });
				// dot.appendChild(button);
				// dotContainer.appendChild(dot);
			}

			// The function to loop.
			var intTest = () => {
				// Reset animation index.
				if (animIndex > length - 1) { animIndex = 0 };
				// Check for each item if it has the same number as the current iteration index.
				for (i = 0; i < length; i++) {
					if (i == animIndex) {
						sliderChild[i].dataset.sliderActive = true;
					} else {
						sliderChild[i].dataset.sliderActive = false;
					}
				}
				animIndex++;
			};
			setInterval(intTest, animTime);
		}
	};

	// Start animation.
	const slidersToInit = document.querySelectorAll('.object-slidewrap');
	slidersToInit && slidersToInit.forEach(slider => {
		let id = '#'+slider.id;
		let time = slider.dataset.slideDuration;
		sliderInit(id, time);
	})

	(function (window, document) {
    
		const buttonObj = document.querySelector(".header-form__button");
  		const body = document.body;

		const hiddenClass = "hidden";
		const revealClass = "revealed";
		
		const downTolerance = 8;
		let hasScrolled = false;
		let lastScrollTop = 0;
	 
		var _redraw = function () {
		const pageY = window.scrollY;

		if (pageY > (lastScrollTop + downTolerance) ) {
			body.classList.add(revealClass);
			body.classList.add('scrolled');
		}
		if (pageY < lastScrollTop || pageY <= 0) {
			buttonObj.classList.remove(revealClass);
			body.classList.remove('scrolled');
		}
		if (pageY <= (0 + downTolerance)) {
			body.classList.add('top');
		}
		if (pageY >= (0 + downTolerance)) {
			body.classList.remove('top');
		}

		lastScrollTop = pageY;
		hasScrolled = false;
		};
	 
		// Important: keep this function as performant as possible!
		var _onScroll = function () {
		  if (!hasScrolled) {
			 window.requestAnimationFrame(_redraw);
		  }
		  hasScrolled = true;
		  window.requestAnimationFrame(_onScroll);
		};
	 
		_onScroll();
	 
	}(window, document));
	

});